* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Expanded", sans-serif;
}

html {
  height: 100%;
  max-width: 100%;
}

body {
  height: 100%;
  max-width: 100%;
}

.card:hover .link {
  opacity: 1;
}

.card:hover .main {
  opacity: 0.2;
}

.active {
  color: #eb9800;
}
